import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";

import pdfImage from "../img/pdf-icon.png";

const Step10_Confirmation = () => {
    const [resources, setResources] = useState({});
    const [order, setOrder] = useState({});
    const [organizerId, setOrganizerId] = useState("");

    const [token] = useState(sessionStorage.getItem("token"));
    const [isAlltron] = useState(sessionStorage.getItem("IsAlltron"));

    // fetching resources
    const { language, orderId } = useParams();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    useEffect(() => {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;

        requestResources();
        requestFormSettings();
    }, [language]);

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            setOrganizerId(res.data.organizerId);
            loadOrder(orderId);
        });
    };

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.log(error.response.data));
    };

    const loadOrder = async (orderId) => {
        try {
            // Order
            const updatedOrder = await getOrderByOrderId(orderId);
            setOrder(updatedOrder);
        }
        catch (error) {
            console.error(error);
        }
    }

    const getOrderByOrderId = async (orderId) => {
        try {
            const res = await axios.get(`${baseUrl}/Order/${orderId}`);
            return res.data;
        }
        catch (error) {
            console.error(error);
        }
    }

    const getPdfTicketsOfOrder = async () => {
        await axios
            .get(
                `${baseUrl}/Order/${orderId}/TicketsPdf?organizerId=${organizerId}`,
                {
                    responseType: "blob",
                }
            )
            .then((res) => {
                const data = window.URL.createObjectURL(res.data);
                var link = document.createElement("a");
                link.href = data;
                link.download = "Tickets.pdf";
                link.click();
            })
            .catch((error) => console.log(error.response.data));
    };

    console.log(isAlltron);

    return (
        <div>
            {resources.translation && (
                <div className="container wrapper">
                    <div className="m-5">
                        <div className="mt-4">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <p>Bei der Versandart «Web/Print@Home» müssen Sie die PDF-Datei öffnen und ausdrucken. Zum Öffnen wird der Adobe Reader benötigt.</p>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-8 mt-4">
                                    Ihr Ticket:
                                    <img src={pdfImage} className="pdf-icon" alt="car picture"></img>
                                    <button
                                        className="custom-button form-control pdf-button"
                                        onClick={() => getPdfTicketsOfOrder()}
                                    >
                                        TICKET HERUNTERLADEN
                                    </button>
                                </div>
                            </div>

                            <div className="row mt-4 pt-1">
                                <div className="col-md-12">
                                    <p>Zusätzlich erhalten Sie noch ein Mobile-Ticket per E-Mail.</p>
                                </div>
                                <div className="col-md-12">
                                    {isAlltron === "true" ? (
                                        <p>Fragen zur Veranstaltung: <a href="https://www.alltron.ch/partnerevent">https://www.alltron.ch/partnerevent</a></p>
                                    ) : (
                                        <p>Fragen zur Veranstaltung: <a href="https://www.brack.ch/b2b-event">https://www.brack.ch/b2b-event</a></p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <p className="mt-5 text-center">
                        {resources.translation && (
                            <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                        )}
                    </p>
                </div>
            )}
        </div>
    );
};

export default Step10_Confirmation;
