import axios from "axios";
import React, { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Tooltip } from 'react-tooltip'
import { BsFillInfoCircleFill } from "react-icons/bs";
import Autosuggest from 'react-autosuggest';

import Loading from "./components/Loading";
import "../css/style.css"

const Home = () => {
    const [orderId, setOrderId] = useState("");
    const [eventId, setEventId] = useState("");
    const [loading, setLoading] = useState(true);
    const [isAlreadyCustomer, setIsAlreadyCustomer] = useState();
    const [isOfferChecked, setIsOfferChecked] = useState(false);
    const [isAlltron, setIsAlltron] = useState(true);


    const [user, setUser] = useState({
        salutation: "",
        firstname: "",
        lastname: "",
        company: "",
        function: "",
        street: "",
        place: "",
        city: "",
        country: "176",
        phone: "",
        email: "",
        confirmemail: "",
        tickettypeid: "",
        customernumber: "",
    });

    // react hook for navigation
    let navigate = useNavigate();

    // base url
    let baseUrl = process.env.REACT_APP_BASEURL_API;

    // fetching resources
    const [resources, setResources] = useState({});

    const { language, formtype } = useParams();

    useEffect(() => {
        if (formtype === "alltron") {
            setIsAlltron(true);
            sessionStorage.setItem("IsAlltron", true);
        } else if (formtype === "brack") {
            setIsAlltron(false);
            sessionStorage.setItem("IsAlltron", false);
        } else {
            // Navigate to PageNotFound if formtype is neither alltron nor brack
            navigate(`/${language}/PageNotFound`);
        }

        loadToken();
    }, []); //only on first page load

    const loadToken = () => {
        axios.get("form/token").then((res) => {
            axios.defaults.headers.common["Authorization"] = "Bearer " + res.data;

            sessionStorage.setItem("token", res.data);

            requestFormSettings();
        });
    };

    const requestFormSettings = () => {
        axios.get(`form/formsettings`).then((res) => {
            startOrder(res.data.posId, res.data.eventId);
            setEventId(res.data.eventId);
        });
    };

    useEffect(() => {
        requestResources();
    }, [language]); //everytime language is changed

    const requestResources = () => {
        axios
            .get(`form/resources/${language}`)
            .then((res) => {
                setResources(res.data);
            })
            .catch((error) => console.error(error.response.data));
    };

    const startOrder = (posId, eventId) => {
        let order = {
            affiliateShopId: null,
            currency: "CHF",
            tenantId: 1,
            pointOfSaleId: posId,
            abbreviation: "",
            paymentType: 1,
        };

        axios
            .post(`${baseUrl}/ShopBasket/Order`, order)
            .then((res) => {
                sessionStorage.setItem("OrderId", res.data.id);

                setOrderId(res.data.id);
                setLoading(true);

                setTimeout(() => setLoading(false), 500);
            })
            .catch((error) => console.error(error.response.data));
    };

    const onSubmit = async () => {
        await addAddressToBasket();
    };

    const addAddressToBasket = async () => {
        let addressBody = {
            salutation: user.salutation,
            firstname: user.firstname,
            name: user.lastname,
            function: user.function,
            company: user.company,
            street: user.street,
            postCode: user.place,
            city: user.city,
            telephone: user.phone,
            countryId: user.country,
            email: user.email,
            code1: user.customernumber,
            code2: "Messeangebote: " + isOfferChecked
        };

        await axios
            .put(`${baseUrl}/Order/${orderId}/Address`, addressBody)
            .then(() => addTicketToBasket())
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const addTicketToBasket = async () => {
        let addTicketTypes = [];

        let option1Text = "BRACK.CH Business Event";

        if (isAlltron) {
            option1Text = "Alltron Partnerevent";
        }

        // check if its both days
        if (user.tickettypeid.includes("_")) {
            const ticketTypeIds = user.tickettypeid.split("_");
            const ticketTypeId1 = ticketTypeIds[0];
            const ticketTypeId2 = ticketTypeIds[1];

            addTicketTypes = [{ ticketTypeId: ticketTypeId1, quantity: 1 }, { ticketTypeId: ticketTypeId2, quantity: 1, option1: option1Text }];
        } else {
            addTicketTypes = [{ ticketTypeId: user.tickettypeid, quantity: 1, option1: option1Text }];
        }

        await axios
            .post(`${baseUrl}/ShopBasket/Order/${orderId}/Tickets`, {
                ticketsToAdd: addTicketTypes,
            })
            .then(async () => {
                const response = await confirmShopbasketByOrderId(orderId);
                if (response) {
                    navigate(`/${language}/confirmation/${orderId}`);
                }
            })
            .catch((error) => {
                console.error(error.response.data);
            });
    };

    const confirmShopbasketByOrderId = async (orderId) => {
        try {
            const res = await axios.put(`${baseUrl}/ShopBasket/Order/${orderId}/Confirm`);
            return res.data;
        } catch (error) {
            console.error(error)
        }
    };

    const onCheckedChange = (e) => {
        if (e.target.value.includes(resources.translation._Mr)) {
            setUser({ ...user, ["salutation"]: resources.translation._Mr });
        }

        if (e.target.value.includes(resources.translation._Mrs)) {
            setUser({ ...user, ["salutation"]: resources.translation._Mrs });
        }

        if (e.target.value.includes(resources.translation._Divers)) {
            setUser({ ...user, ["salutation"]: resources.translation._Divers });
        }
    }

    const onIsAlreadyCustomerChange = (e) => {
        if (e.target.value.includes("true")) {
            setIsAlreadyCustomer(true);
        } else {
            setIsAlreadyCustomer(false);
            setUser({ ...user, customernumber: "" });
        }
    }

    const onInputChange = (e) => {
        setUser({ ...user, [e.target.name]: e.target.value });
    };

    // autosuggestion for personfunktion
    const personFunctions = [
        { name: 'Abteilungsleiter*in' },
        { name: 'Account Manager*in' },
        { name: 'Administration' },
        { name: 'Backoffice' },
        { name: 'Buchhaltung' },
        { name: 'Business Development' },
        { name: 'Category Manager*in' },
        { name: 'CEO' },
        { name: 'CFO' },
        { name: 'CIO' },
        { name: 'CMO' },
        { name: 'Consultant' },
        { name: 'COO' },
        { name: 'CSO' },
        { name: 'CTO' },
        { name: 'Digital Marketing Manager*in' },
        { name: 'Einkauf' },
        { name: 'Geschäftsführer*in' },
        { name: 'Geschäftsleitung' },
        { name: 'Head of IT' },
        { name: 'Head of Marketing' },
        { name: 'Head of Sales' },
        { name: 'ICT-Support' },
        { name: 'IT-Support' },
        { name: 'Marketingleiter*in' },
        { name: 'Marketingmanager*in' },
        { name: 'Product Manager*in' },
        { name: 'Projektleiter*in' },
        { name: 'Projektleiter*in ICT' },
        { name: 'Projektleiter*in IT' },
        { name: 'Projektleiter*in Marketing' },
        { name: 'Sales Consultant' },
        { name: 'Sales Manager*in' },
        { name: 'Senior Account Manager' },
        { name: 'Senior Consultant' },
        { name: 'Senior Projektleiter*in' },
        { name: 'Strategischer Einkauf' },
        { name: 'Stv. Geschäftsführer*in' },
        { name: 'Support' },
        { name: 'System Engineer*in' },
        { name: 'Systemtechniker*in' },
        { name: 'Technik' },
        { name: 'Telekom' },
        { name: 'Telematiker*in' },
        { name: 'Verkauf' },
        { name: 'Verkaufsleiter*in' },
        { name: 'Vertriebsleiter*in' },
        { name: 'Verwaltungsrat' }
    ];

    // validating input fields
    let validation =
        /^([a-z A-Zöéàäèü]{1,70})$/.test(user.salutation) &&
        /^.{1,70}$/.test(user.firstname) &&
        /^.{1,70}$/.test(user.lastname) &&
        /^.{1,70}$/.test(user.company) &&
        /^.{1,70}$/.test(user.function) &&
        /^.{1,70}$/.test(user.street) &&
        /^.{1,70}$/.test(user.place) &&
        /^.{1,70}$/.test(user.city) &&
        /^.{1,70}$/.test(user.phone) &&
        /^.{1,70}$/.test(user.tickettypeid) &&
        /^([a-z A-Zöéàäèü 0-9 - ]{1,70})$/.test(user.country) &&
        /^\S+@\S+\.\S+$/.test(user.email) &&
        /^\S+@\S+\.\S+$/.test(user.confirmemail) &&
        user.email === user.confirmemail;

    if (isAlreadyCustomer && user.customernumber === "") validation = false;

    ////check if autosuggestion selection is valid
    //if (!personFunctions.some(personFunction => user.function.includes(personFunction.name))) validation = false;

    // autosuggestion for personfunktion
    const [value, setValue] = useState('');
    const [suggestions, setSuggestions] = useState([]);

    const getSuggestions = (inputValue) => {
        const inputValueLowerCase = inputValue.toLowerCase();
        return personFunctions.filter((lang) =>
            lang.name.toLowerCase().startsWith(inputValueLowerCase)
        );
    };

    const onSuggestionsFetchRequested = ({ value }) => {
        setSuggestions(getSuggestions(value));
    };

    const onSuggestionsClearRequested = () => {
        setSuggestions([]);
    };

    const onChange = (event, { newValue }) => {
        setValue(newValue);
        setUser({ ...user, function: newValue });
    };

    const getSuggestionValue = (suggestion) => suggestion.name;

    const renderSuggestion = (suggestion) => (
        <div className="react-autosuggest-div" role="button">
            {suggestion.name}
        </div>
    );

    const inputProps = {
        placeholder: '',
        value,
        onChange: onChange
    };

    return (
        <div>
            {/* loop the ticket types */}
            {loading ? (
                <>
                    <div className="d-none d-lg-block">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="50%"
                            left="50%"
                        />
                    </div>
                    <div className="d-lg-none">
                        <Loading
                            alignment="center"
                            color="#d3d3d3"
                            bgColor="#fff"
                            position="fixed"
                            top="45%"
                            left="45%"
                        />
                    </div>
                </>
            ) : (
                <>
                    {resources.translation && (
                        <div className="container wrapper">
                            <div className="m-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>
                                            {isAlltron ? (
                                                resources.translation.AlltronTitle
                                            ) : (
                                                resources.translation.BrackTitle
                                            )}
                                        </h4>

                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <b>
                                            {isAlltron ? (
                                                resources.translation.AlltronSubtitle
                                            ) : (
                                                resources.translation.BrackSubtitle
                                            )}
                                        </b>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <select
                                            className="form-control custom-dropdown"
                                            name="tickettypeid"
                                            value={user.tickettypeid}
                                            onChange={(e) => onInputChange(e)}>
                                            <option value="">{resources.translation.HomePleaseChoose}</option>
                                            <option disabled="disabled">---------------------------------------------------------</option>
                                            {isAlltron ? (
                                                <>
                                                    <option value="752416">{resources.translation.HomeFirstDay}</option>
                                                    <option value="752417">{resources.translation.HomeSecondDay}</option>
                                                    <option value="752416_752417">{resources.translation.HomeBothDays}</option>
                                                </>
                                            ) : (
                                                <>
                                                    <option value="752418">{resources.translation.HomeFirstDay}</option>
                                                    <option value="752419">{resources.translation.HomeSecondDay}</option>
                                                    <option value="752418_752419">{resources.translation.HomeBothDays}</option>
                                                </>
                                            )}
                                        </select>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <p>Bitte folgende Hinweise beachten:</p>
                                        <ul>
                                            <li>Alle Besucherinnen und Besucher benötigen ein persönliches Ticket.</li>
                                            <li>Pro Bestellung ist nur ein Ticket möglich (für einen oder für beide Messetag(e)).</li>
                                        </ul>
                                    </div>
                                </div>


                                {/* user data */}

                                <div className="row mt-3">
                                    <div className="col-md-12 mt-1">
                                        <span>Ihr Kundenstatus:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <input
                                            id="YesCustomer"
                                            type="radio"
                                            name="customerstatus"
                                            value="true"
                                            onChange={(e) => onIsAlreadyCustomerChange(e)}
                                            required
                                        />
                                        {isAlltron ? (
                                            <label className="ms-2" htmlFor="YesCustomer">Ja, ich bin bereits Kunde bei Alltron</label>
                                        ) : (
                                            <label className="ms-2" htmlFor="YesCustomer">Ja, ich bin bereits Kunde bei BRACK.CH Business</label>
                                        )}
                                    </div>
                                    <div className="col-md-12">
                                        <input
                                            id="notCustomer"
                                            type="radio"
                                            name="customerstatus"
                                            value="false"
                                            onChange={(e) => onIsAlreadyCustomerChange(e)}
                                            required
                                        />
                                        {isAlltron ? (
                                            <label className="ms-2" htmlFor="notCustomer">Ich bin noch nicht Kunde bei Alltron</label>
                                        ) : (
                                            <label className="ms-2" htmlFor="notCustomer">Ich bin noch nicht Kunde bei BRACK.CH Business</label>
                                        )}
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-12 mt-3">
                                        <span>Bitte geben Sie nachfolgend Ihre Daten ein:</span>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="col-md-3 mt-3">
                                        <span>Anrede:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            id="mr"
                                            type="radio"
                                            name="salutation"
                                            value={resources.translation._Mr}
                                            onChange={(e) => onCheckedChange(e)}
                                            required
                                        />
                                        <label className="ms-2" htmlFor="mr">Herr</label>

                                        <input
                                            id="mrs"
                                            type="radio"
                                            className="ms-4"
                                            name="salutation"
                                            value={resources.translation._Mrs}
                                            onChange={(e) => onCheckedChange(e)}
                                            required
                                        />
                                        <label className="ms-2" htmlFor="mrs">Frau</label>

                                        <input
                                            id="divers"
                                            type="radio"
                                            className="ms-4"
                                            name="salutation"
                                            value={resources.translation._Divers}
                                            onChange={(e) => onCheckedChange(e)}
                                            required
                                        />
                                        <label className="ms-2" htmlFor="divers">Divers</label>
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col-md-3 mt-3">
                                        <span>Vorname:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="text"
                                            name="firstname"
                                            className="form-control customTextbox"
                                            value={user.firstname}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>Nachname:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="text"
                                            name="lastname"
                                            className="form-control customTextbox"
                                            value={user.lastname}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>Funktion:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <Autosuggest
                                            suggestions={suggestions}
                                            onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                                            onSuggestionsClearRequested={onSuggestionsClearRequested}
                                            getSuggestionValue={getSuggestionValue}
                                            renderSuggestion={renderSuggestion}
                                            inputProps={inputProps}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>Firma:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="text"
                                            name="company"
                                            className="form-control customTextbox"
                                            value={user.company}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                {isAlreadyCustomer &&
                                    <div className="row mt-1">
                                        <div className="col-md-3 mt-3">
                                            <span>Kundennummer:</span><span className="red">*</span>
                                            {isAlltron ? (
                                                <span className="ms-1"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-html="Nach der erfolgreichen Anmeldung im Onlineshop<br/> erscheint Ihre Kundennummer auf gleicher Höhe<br/> wie der Anmelde-Button."
                                                    data-tooltip-place="top">
                                                    <BsFillInfoCircleFill />
                                                </span>
                                            ) : (
                                                <span className="ms-1"
                                                    data-tooltip-id="my-tooltip"
                                                    data-tooltip-html="Nach der erfolgreichen Anmeldung im Onlineshop<br/> erscheint Ihr Name auf der gleichen Höhe wie der<br/> Anmelde-Button. Klicken Sie auf Ihren Namen.<br/> Ihre Kundennummer finden Sie anschliessend als<br/> erste Angabe unter 'Übersicht'."
                                                    data-tooltip-place="top">
                                                    <BsFillInfoCircleFill />
                                                </span>
                                            )}
                                            <Tooltip id="my-tooltip" />
                                        </div>
                                        <div className="col-md-9 mt-3">
                                            <input
                                                type="text"
                                                name="customernumber"
                                                className="form-control customTextbox"
                                                value={user.customernumber}
                                                onChange={(e) => onInputChange(e)}
                                                required
                                            />
                                        </div>
                                    </div>
                                }

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>Strasse:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="text"
                                            name="street"
                                            className="form-control customTextbox"
                                            value={user.street}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>PLZ/Ort:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-3 mt-3">
                                        <input
                                            type="text"
                                            name="place"
                                            className="form-control customTextbox"
                                            value={user.place}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                    <div className="col-md-6 mt-3">
                                        <input
                                            type="text"
                                            name="city"
                                            className="form-control customTextbox"
                                            value={user.city}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>Telefon:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="text"
                                            name="phone"
                                            className="form-control customTextbox"
                                            value={user.phone}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>E-Mail:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-control customTextbox"
                                            value={user.email}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-1">
                                    <div className="col-md-3 mt-3">
                                        <span>{resources.translation.HomeEmailConfirmation}:</span><span className="red">*</span>
                                    </div>
                                    <div className="col-md-9 mt-3">
                                        <input
                                            type="email"
                                            name="confirmemail"
                                            className="form-control customTextbox"
                                            value={user.confirmemail}
                                            onChange={(e) => onInputChange(e)}
                                            required
                                        />
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <input id="chkOffer" type="checkbox" checked={isOfferChecked} onChange={() => setIsOfferChecked(!isOfferChecked)} />
                                        <label htmlFor="chkOffer" className="ms-2">Gerne dürfen Sie mir einmalig die Messeangebote per E-Mail senden.</label>
                                    </div>
                                </div>

                                <div className="row mt-4">
                                    <div className="col-md-12">
                                        <span>* Bitte füllen Sie die Pflichtfelder aus.</span>
                                    </div>
                                </div>


                                {/* button confirm */}

                                <div className="row mt-5">
                                    <div className="offset-md-6 col-md-6 text-end">
                                        <button
                                            className="custom-button"
                                            onClick={onSubmit}
                                            disabled={!validation}
                                        >
                                            {resources.translation.HomeNext}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <p className="mt-5 text-center">
                                {resources.translation && (
                                    <small style={{ color: 'grey' }}>powered by <a style={{ color: 'grey' }} href={resources.translation._TicketinoOrganizerUrl} target="_blank">TICKETINO</a></small>
                                )}
                            </p>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default Home;
